import { render, staticRenderFns } from "./TrainingVideoDetail.vue?vue&type=template&id=56db69f0&scoped=true"
import script from "./TrainingVideoDetail.vue?vue&type=script&lang=ts"
export * from "./TrainingVideoDetail.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56db69f0",
  null
  
)

export default component.exports